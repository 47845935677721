import { Link } from 'gatsby';
import { AiOutlineMail } from 'react-icons/ai';
import { BiPhone } from 'react-icons/bi';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import tw from 'twin.macro';

const Footer = () => (
  <footer
    css={tw`flex flex-col px-6 py-12 sm:px-16 bg-brand-bluegray font-sans`}
  >
    <div css={tw`grid mb-8 sm:grid-cols-2`}>
      <div css={tw`text-center sm:text-left`}>
        <h1 css={tw`mb-3 font-serif text-3xl`}>Über Ferienfuchs</h1>
        <p css={tw`text-lg text-brand-darkGray`}>
          Ferienfuchs.eu ist dein Online-Reisebüro. Wir sind keine Suchmaschine,
          sondern ein Team von erfahrenden Menschen, die dir jeden Wunsch
          erfüllen!
        </p>
        <div css={tw`flex justify-center my-6 sm:mt-6 sm:justify-start`}>
          <a href="https://facebook.com/ferienfuchs.eu">
            <div
              css={tw`p-4 mr-2 text-2xl rounded-full text-brand-orange bg-brand-lightOrange hover:text-brand-darkOrange`}
            >
              <FaFacebookF />
            </div>
          </a>
          <a href="https://instagram.com/ferienfuchs.eu">
            <div
              css={tw`p-4 mr-2 text-2xl rounded-full text-brand-orange bg-brand-lightOrange hover:text-brand-darkOrange`}
            >
              <FaInstagram />
            </div>
          </a>
        </div>
      </div>
      <div
        css={tw`grid grid-cols-1 text-lg text-center sm:text-left sm:grid-cols-2 sm:pl-16 text-brand-darkGray`}
      >
        <div css={tw`flex flex-col mb-6 sm:mb-0`}>
          <h1 css={tw`mb-3 font-serif text-3xl text-black`}>Seiten</h1>
          <Link css={tw`mb-2`} to="/ziele">
            Ziele
          </Link>
          <Link css={tw`mb-2`} to="/aktivitaeten">
            Aktivitäten
          </Link>
          <Link css={tw`mb-2`} to="/premium">
            Premium
          </Link>
        </div>
        <div css={tw`flex flex-col items-center sm:items-start`}>
          <h1 css={tw`mb-3 font-serif text-3xl text-black`}>Kontakt</h1>
          <div css={tw`flex items-center mb-2 `}>
            <AiOutlineMail css={tw`mr-2 text-2xl text-brand-orange`} />
            <a href="mailto:info@ferienfuchs.eu">info@ferienfuchs.eu</a>
          </div>
          <div css={tw`flex items-center mb-2`}>
            <BiPhone css={tw`mr-2 text-2xl text-brand-orange`} />
            <a href="tel:015254039933">01525/4039933</a>
          </div>
          <div css={tw`flex items-center mb-2`}>
            <HiOutlineLocationMarker
              css={tw`mr-2 text-2xl text-brand-orange`}
            />
            Kegelswies 43
            <br />
            53773 Hennef
          </div>
        </div>
      </div>
    </div>
    <div
      css={tw`grid grid-cols-1 text-center sm:text-left sm:items-center sm:justify-between sm:flex text-brand-darkGray`}
    >
      © {new Date().getFullYear()}, Ferienfuchs.eu. All rights reserved.
      <div>
        <Link css={tw`mr-2 sm:mr-8`} to="/impressum">
          Impressum
        </Link>
        <Link to="/datenschutz">Datenschutz</Link>
      </div>
    </div>
  </footer>
);

export default Footer;
