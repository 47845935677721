import React from 'react';
import tw from 'twin.macro';
import Nav from './Nav';
import Footer from './Footer';

export default ({ children }) => (
  <div css={tw`flex flex-col min-h-screen overflow-x-hidden`}>
    <Nav />
    <main css={tw`flex-1 font-sans`}>{children}</main>
    <Footer />
  </div>
);
